import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { UserProfile } from "./types";
import { profileQuerySettings } from "./users";

export function useProfile(
  options: Omit<UseQueryOptions<UserProfile>, "queryKey" | "queryFn"> = {},
) {
  return useQuery<UserProfile>({ ...profileQuerySettings, ...options });
}

export function useProfileOrganizationId(
  options: Omit<UseQueryOptions<UserProfile>, "queryKey" | "queryFn"> = {},
) {
  const { data: profile, isLoading } = useProfile(options);
  return { organizationId: profile?.organization.id, isLoading };
}
