import { tokenManager } from "./auth-client";

export class APIClient {
  private baseURL: string;

  constructor(config?: { baseURL?: string }) {
    this.baseURL = config?.baseURL || process.env.NEXT_PUBLIC_API_URL || "/";
  }

  private async getAuthToken(): Promise<string | null> {
    // Handle token retrieval for client-side
    if (typeof window !== "undefined") {
      return await tokenManager.getToken();
    }

    // Handle token retrieval for server-side
    const { cookies } = require("next/headers");
    return cookies().get("auth_token")?.value ?? null;
  }

  async fetch<T = any>(endpoint: string, init?: RequestInit): Promise<T> {
    try {
      const token = await this.getAuthToken();
      if (!token) {
        throw new Error("No token available");
      }

      const url = endpoint.startsWith(this.baseURL) ? endpoint : `${this.baseURL}${endpoint}`;
      const response = await fetch(url, {
        ...init,
        headers: {
          "Content-Type": "application/json",
          ...init?.headers,
          Authorization: `Token ${token}`,
        },
      });

      if (!response || response.status === 401) {
        throw new Error("Unauthorized");
      }

      if (response.status < 200 || response.status >= 300) {
        const cause = response.headers.get("content-type")?.includes("application/json")
          ? await response.json()
          : { message: response.statusText };
        throw new Error(`API error: ${response.statusText}`, { cause });
      }
      if (response.status === 204) {
        return null as T;
      }
      return response.json();
    } catch (error) {
      if (error instanceof Error && error.message === "Unauthorized") {
        // Only clear token on 401 responses
        tokenManager.clearToken();
      }
      throw error;
    }
  }
}
