import { ApiUserProfile, UserProfile } from "./types";
import { APIClient } from "src/lib/api-client";

export const profileQuerySettings = {
  queryKey: ["userProfile"] as const,
  queryFn: () => getProfile(),
};

export async function getProfile() {
  const client = new APIClient();
  const res = await client.fetch<ApiUserProfile>(`/api/v1/profile/`);

  return {
    id: res.id,
    username: res.username,
    name: res.name ?? res.username ?? "",
    email: res.email,
    isStaff: res.is_staff,
    isActive: res.is_active,
    isSuperuser: res.is_superuser,
    isIfm: res.is_ifm,
    isSupplier: res.is_supplier,
    dateJoined: res.date_joined,
    groups: res.groups,
    organization: {
      id: res.organization.id,
      name: res.organization.name,
      subDomain: res.organization.sub_domain,
      organizationTypes: res.organization.organization_types,
    },
    magicbellKey: res.magicbell_key,
    isTosAccepted: res.is_tos_accepted,
  } as UserProfile;
}
